import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useHistory,useLocation  } from 'react-router-dom';
import Preloader from '../Preloader/Preloader';
import Header from '../Header/Header';
import Contacts from '../Contacts/Contacts'
import NotFound from '../NotFound/NotFound';
import './App.css';
import ImagePopup from '../ImagePopup/ImagePopup';

const Main = React.lazy(() => import("../Main/Main"));
const Chapter = React.lazy(() => import("../Chapter/Chapter"));
const Card = React.lazy(() => import("../Card/Card"));

function App() {
  
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
 
    if (hash === '') {
      window.scrollTo(0, 0);
    }

    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  const [selectedCard, setSelectedCard] = React.useState({  model: "", name: "", link: "" });

  function closeAllPopus() {
    setSelectedCard({ model: "", name: "", link: "" });
  }

  function handleCardClick(card) {
    setSelectedCard(card);
  }


  useEffect(() => {
 
    if (pathname !== '/catalog/:chapter/:id') {
      setSelectedCard({ model: "", name: "", link: "" });
    }

  }, [pathname, hash, key]);
  

  return (
    <Suspense fallback={<Preloader />}>
      <div className='app'>
        <Header />
        <Switch>
          <Route path='/' exact>
            <Main />            
          </Route>
          <Route path='/catalog/:chapter' exact>
           <Chapter/>
          </Route>
          <Route path='/catalog/:chapter/:id' >
            <Card  onCardClick={handleCardClick} />
          </Route>
          <Route path='/contacts' exact>
           <Contacts/>
          </Route>
          <Route path='*' >
            <NotFound />
          </Route>
          
        </Switch>
        <ImagePopup card={selectedCard} onClose={closeAllPopus}/>
      </div>
    </Suspense>
  );
}

export default App;
