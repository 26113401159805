import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import './Navigation.css';

function Navigation() {


  return (
    <nav className='navigation__menu'>
      <ul className='navigation__list'>
        <li className='navigation__item'>
          <Link to={{
              pathname: "/",
              hash: "#catalog",
            }} className='navigation__link'>
            Каталог
          </Link>
        </li>
        <li className='navigation__item navigation__item_type_contact'>
          <NavLink
            to='/contacts'
            className='navigation__link navigation__link_type_contact'
          >
            Связаться с нами
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
