
import React from 'react';
import './ImagePopup.css'



function ImagePopup(props) {
  return (
    <div className={`popup image-popup ${props.card.link && 'popup_is-opened'}`}  onClick={(e) => (e.currentTarget === e.target) && props.onClose()} >
      <div className="popup__image-container">
        <button type="button" className="popup__close-button" onClick={props.onClose} />
      
        <img src={props.card.link} alt="Картинка Места" className="popup__image" draggable="false" />   
        <h2 className="popup__image-title" draggable="false" >{props.card.model} <br></br><br></br>{props.card.name}</h2>     
      </div>
    </div>
  )
}

export default ImagePopup;